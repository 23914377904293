"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeyMapService = void 0;
class KeyMapService {
  constructor() {
    this.enumToLong = {};
    this.shortToEnum = {};
    this.longToEnum = {};
    this.enumToShort = {};
    this.enumToLong["ABBREV"] = "abbrev";
    this.enumToLong["ABBREV_NAME"] = "abbrevName";
    this.enumToLong["ATHLETICS_CANADA_LINK"] = "athleticsCanadaLink";
    this.enumToLong["ACCOUNT_ID"] = "accountId";
    this.enumToLong["AD_TYPE"] = "adType";
    this.enumToLong["ADVANCE_NOTES"] = "advanceNotes";
    this.enumToLong["AGE"] = "age";
    this.enumToLong["AGE_GRADE_MARK"] = "ageGradeMark";
    this.enumToLong["AGE_GRADE_PCT"] = "ageGradePct";
    this.enumToLong["AGE_GROUP"] = "ageGroup";
    this.enumToLong["AGE_GROUPS"] = "ageGroups";
    this.enumToLong["ALLOW_ATHLETE_NOTIFICATIONS"] = "allowAthleteNotifications";
    this.enumToLong["ALLOW_FIELD_SCOREBOARD"] = "allowFieldScoreboard";
    this.enumToLong["ALLOW_FREE_ATHLETE_VIDEO_DOWNLOADS"] = "allowFreeAthleteVideoDownloads";
    this.enumToLong["ALLOW_FREE_MY_VIDEO_VIEWS"] = "allowFreeMyVideoViews";
    this.enumToLong["ALLOW_NOTIFICATIONS"] = "allowNotifications";
    this.enumToLong["ALLOW_SCOREBOARD"] = "allowScoreboard";
    this.enumToLong["ALLOW_TEAM_NOTIFICATIONS"] = "allowTeamNotifications";
    this.enumToLong["ALLOW_TEXT_NOTIFICATIONS"] = "allowTextNotifications";
    this.enumToLong["ALLOW_VIDEO_DOWNLOADS"] = "allowVideoDownloads";
    this.enumToLong["ARCHIVE_COUNTS"] = "archiveCounts";
    this.enumToLong["ARMED_RUNNING"] = "armedRunning";
    this.enumToLong["ATHLETE"] = "athlete";
    this.enumToLong["ATHLETE_ID"] = "athleteId";
    this.enumToLong["ATHLETIC_LIVE_ID"] = "athleticLiveId";
    this.enumToLong["ATHLETIC_LIVE_TEAM_ID"] = "athleticLiveTeamId";
    this.enumToLong["ATHLETIC_NET_EVENT_ID"] = "athleticNetEventId";
    this.enumToLong["ATHLETIC_NET_ID"] = "athleticNetId";
    this.enumToLong["ATHLETIC_NET_LOGO"] = "athleticNetLogo";
    this.enumToLong["ATHLETIC_NET_MEETS"] = "athleticNetMeets";
    this.enumToLong["ATTEMPT"] = "attempt";
    this.enumToLong["ATTRIBUTIONS"] = "attributions";
    this.enumToLong["AVG_KM"] = "avgKm";
    this.enumToLong["AVG_MILE"] = "avgMile";
    this.enumToLong["AWARD_COUNT"] = "awardCount";
    this.enumToLong["AWARD_LIST_DATE"] = "awardListDate";
    this.enumToLong["AWARD_LIST_ID"] = "awardListId";
    this.enumToLong["AWARD_LIST_VERSION"] = "awardListVersion";
    this.enumToLong["AWARD_LISTS"] = "awardLists";
    this.enumToLong["BEST_MARK"] = "bestMark";
    this.enumToLong["BEST_SPLIT"] = "bestSplit";
    this.enumToLong["BIB_LIST"] = "bibList";
    this.enumToLong["BLOCK_VIDEOS"] = "blockVideos";
    this.enumToLong["CANCELLED"] = "cancelled";
    this.enumToLong["CHECK_IN"] = "checkIn";
    this.enumToLong["CHECK_IN_DATE"] = "checkInDate";
    this.enumToLong["CHECK_IN_END_DATE_MILLISECONDS"] = "checkInEndDateMs";
    this.enumToLong["COMBINED_ENTRIES"] = "combinedEntries";
    this.enumToLong["COMBINED_EVENT"] = "combinedEvent";
    this.enumToLong["COMBINED_EVENT_ATHLETES"] = "combinedEventAthletes";
    this.enumToLong["COMBINED_EVENT_ID"] = "combinedEventId";
    this.enumToLong["COMBINED_EVENT_NAME"] = "combinedEventName";
    this.enumToLong["COMBINED_EVENT_NUM"] = "combinedEventNum";
    this.enumToLong["COMBINED_EVENT_RESULTS"] = "combinedEventResults";
    this.enumToLong["COMBINED_EVENTS"] = "combinedEvents";
    this.enumToLong["COMBINED_POINTS"] = "combinedPoints";
    this.enumToLong["COMBINED_POINTS_BEHIND"] = "combinedPointsBehind";
    this.enumToLong["COMBINED_RECORD"] = "combinedRecord";
    this.enumToLong["COMBINED_RECORD_ID"] = "combinedRecordId";
    this.enumToLong["COMBINED_RECORDS"] = "combinedRecords";
    this.enumToLong["COMBINED_TIME_STANDARD"] = "combinedTimeStandard";
    this.enumToLong["COMBINED_TIME_STANDARDS"] = "combinedTimeStandards";
    this.enumToLong["COMP_NUM"] = "compNum";
    this.enumToLong["COMPETITION_AREA"] = "competitionArea";
    this.enumToLong["COMPETITION_AREA_NAME"] = "competitionAreaName";
    this.enumToLong["CONCIERGE_NAME"] = "conciergeName";
    this.enumToLong["CONSECUTIVE_DAYS"] = "consecutiveDays";
    this.enumToLong["CONTEXT_CLASS"] = "contextClass";
    this.enumToLong["CONVERSION"] = "conversion";
    this.enumToLong["CREATED_DATE"] = "createdDate";
    this.enumToLong["CUMULATIVE_COMBINED_POINTS"] = "cumulativeCombinedPoints";
    this.enumToLong["CUMULATIVE_SPLIT"] = "cumulativeSplit";
    this.enumToLong["CURRENT_INDIVIDUAL_SPLIT"] = "currentIndividualSplit";
    this.enumToLong["CURRENT_TEAM_SPLIT"] = "currentTeamSplit";
    this.enumToLong["CUSTOM_NAME"] = "customName";
    this.enumToLong["CUSTOM_NOTE"] = "customNote";
    this.enumToLong["DATA_CONTEXT_NAME"] = "dataContextName";
    this.enumToLong["DATE"] = "date";
    this.enumToLong["DATE_STRING"] = "dateString";
    this.enumToLong["DESCRIPTION"] = "description";
    this.enumToLong["DISPLAY_XC_FINISH_RESULTS"] = "displayXcFinishResults";
    this.enumToLong["DISPLAY_XC_INDIVIDUAL_FINISH_RESULTS"] = "displayXcIndividualFinishResults";
    this.enumToLong["DISPLAY_XC_TEAM_FINISH_RESULTS"] = "displayXcTeamFinishResults";
    this.enumToLong["DISTANCE"] = "distance";
    this.enumToLong["DIVISION"] = "division";
    this.enumToLong["DIVISION_BY_ENTRY_EVENTS"] = "divisionByEntryEvents";
    this.enumToLong["DIVISION_BY_TEAM_EVENTS"] = "divisionByTeamEvents";
    this.enumToLong["DIVISION_ID"] = "divisionId";
    this.enumToLong["DIVISIONS"] = "divisions";
    this.enumToLong["DNF"] = "dnf";
    this.enumToLong["DNS"] = "dns";
    this.enumToLong["END_DATE"] = "endDate";
    this.enumToLong["ENTITY_RECORD_NOTE"] = "entityRecordNote";
    this.enumToLong["EVENT"] = "event";
    this.enumToLong["EVENT_ABBREVS"] = "eventAbbrevs";
    this.enumToLong["EVENT_CATEGORY"] = "eventCategory";
    this.enumToLong["EVENT_GROUPS"] = "eventGroups";
    this.enumToLong["EVENT_ID"] = "eventId";
    this.enumToLong["EVENT_NOTES"] = "eventNotes";
    this.enumToLong["EVENT_NUMBER"] = "eventNumber";
    this.enumToLong["EVENT_STATUS"] = "eventStatus";
    this.enumToLong["EVENT_TYPE"] = "eventType";
    this.enumToLong["FACEBOOK_SHARE_URL"] = "facebookShareUrl";
    this.enumToLong["FEATURE_SCORES"] = "featureScores";
    this.enumToLong["FEATURED_PRIMARY"] = "featuredPrimary";
    this.enumToLong["FEATURED_SITE"] = "featuredSite";
    this.enumToLong["FIELD_APP_VIDEO"] = "fieldAppVideo";
    this.enumToLong["FIELD_APP_VIDEO_FREE_HOURS"] = "fieldAppVideoFreeHours";
    this.enumToLong["FIELD_APP_VIDEO_MODEL"] = "fieldAppVideoModel";
    this.enumToLong["FIELD_APP_VIDEO_MODELS"] = "fieldAppVideoModels";
    this.enumToLong["FIELD_APP_VIDEO_SUB_ACCESS"] = "fieldAppVideoSubAccess";
    this.enumToLong["FIELD_APP_VIDEOS"] = "fieldAppVideos";
    this.enumToLong["FIELD_EVENT_ORIENTATION"] = "fieldEventOrientation";
    this.enumToLong["FIELD_SERIES"] = "fieldSeries";
    this.enumToLong["FINAL_POSITION"] = "finalPosition";
    this.enumToLong["FINAL_RESULTS_UPLOADED_AT"] = "finalResultsUploadedAt";
    this.enumToLong["FINAL_RESULTS_UPLOADED_AT_MILLISECONDS"] = "finalResultsUploadedAtMs";
    this.enumToLong["FINISH_LINE_BRIGHTCOVE_ID"] = "finishLineBrightcoveId";
    this.enumToLong["FINISH_PERCENT"] = "finishPercent";
    this.enumToLong["FINISHED"] = "finished";
    this.enumToLong["FIRST_NAME"] = "firstName";
    this.enumToLong["FIRST_PLACE"] = "firstPlace";
    this.enumToLong["FLIGHT_NUMBER"] = "flightNumber";
    this.enumToLong["FLIGHTS"] = "flights";
    this.enumToLong["FONT_ID"] = "fontId";
    this.enumToLong["FULL_NAME"] = "fullName";
    this.enumToLong["FULL_RACE_VIDEO_FREE_HOURS"] = "fullRaceVideoFreeHours";
    this.enumToLong["FULL_RACE_VIDEO_IDS"] = "fullRaceVideoIds";
    this.enumToLong["FULL_RACE_VIDEO_MODEL"] = "fullRaceVideoModel";
    this.enumToLong["FULL_RACE_VIDEO_MODELS"] = "fullRaceVideoModels";
    this.enumToLong["FULL_RACE_VIDEO_SUB_ACCESS"] = "fullRaceVideoSubAccess";
    this.enumToLong["FULL_RACE_VIDEO_TITLES"] = "fullRaceVideoTitles";
    this.enumToLong["GENDER"] = "gender";
    this.enumToLong["GENDER_ABBREV"] = "genderAbbrev";
    this.enumToLong["GENDER_ID"] = "genderId";
    this.enumToLong["GENDER_LABEL"] = "genderLabel";
    this.enumToLong["GENDER_LABELS"] = "genderLabels";
    this.enumToLong["HAS_BIB_LIST"] = "hasBibList";
    this.enumToLong["HAS_ENTRIES"] = "hasEntries";
    this.enumToLong["HAS_FIELD_APP_VIDEOS"] = "hasFieldAppVideos";
    this.enumToLong["HAS_FINISH_LINE_IMAGES"] = "hasFinishLineImages";
    this.enumToLong["HAS_FINISH_LINE_VIDEOS"] = "hasFinishLineVideos";
    this.enumToLong["HAS_FULL_RACE_VIDEOS"] = "hasFullRaceVideos";
    this.enumToLong["HAS_HEATS"] = "hasHeats";
    this.enumToLong["HAS_HORIZONTAL_SERIES"] = "hasHorizontalSeries";
    this.enumToLong["HAS_INDIVIDUAL_ENTRIES"] = "hasIndividualEntries";
    this.enumToLong["HAS_INDIVIDUAL_RESULTS"] = "hasIndividualResults";
    this.enumToLong["HAS_LOGOS"] = "hasLogos";
    this.enumToLong["HAS_POINTS"] = "hasPoints";
    this.enumToLong["HAS_PROFILE_PICS"] = "hasProfilePics";
    this.enumToLong["HAS_RECORDS"] = "hasRecords";
    this.enumToLong["HAS_RELAY_ENTRIES"] = "hasRelayEntries";
    this.enumToLong["HAS_RELAY_RESULTS"] = "hasRelayResults";
    this.enumToLong["HAS_RESULT_HEATS"] = "hasResultHeats";
    this.enumToLong["HAS_RESULTS"] = "hasResults";
    this.enumToLong["HAS_SESSIONS"] = "hasSessions";
    this.enumToLong["HAS_SPLIT_GRIDS"] = "hasSplitGrids";
    this.enumToLong["HAS_SPLITS"] = "hasSplits";
    this.enumToLong["HAS_TIME_STANDARDS"] = "hasTimeStandards";
    this.enumToLong["HAS_TOP_FINISHER_IMAGES"] = "hasTopFinisherImages";
    this.enumToLong["HAS_TRACK_POINT_MVP"] = "hasTrackPointMvp";
    this.enumToLong["HAS_VERTICAL_SERIES"] = "hasVerticalSeries";
    this.enumToLong["HAS_WIND_READINGS"] = "hasWindReadings";
    this.enumToLong["HAS_YEARS"] = "hasYears";
    this.enumToLong["HASH_TAG"] = "hashTag";
    this.enumToLong["HEADER_NOTES"] = "headerNotes";
    this.enumToLong["HEAT"] = "heat";
    this.enumToLong["HEATS"] = "heats";
    this.enumToLong["HEAT_CONTEXT_CLASS"] = "heatContextClass";
    this.enumToLong["HEAT_NUMBER"] = "heatNumber";
    this.enumToLong["HEAT_PLACE"] = "heatPlace";
    this.enumToLong["HEAT_PLACE_CHANGE"] = "heatPlaceChange";
    this.enumToLong["HEAT_PLACE_CHANGE_DIRECTION"] = "heatPlaceChangeDirection";
    this.enumToLong["HEAT_PLACE_INTEGER"] = "heatPlaceInteger";
    this.enumToLong["HEAT_WIND_READINGS"] = "heatWindReadings";
    this.enumToLong["HEIGHT"] = "height";
    this.enumToLong["HIDE_ON_EVENT_LIST"] = "hideOnEventList";
    this.enumToLong["HIDE_SCORES"] = "hideScores";
    this.enumToLong["HOLDER"] = "holder";
    this.enumToLong["HORIZONTAL_ATTEMPTS"] = "horizontalAttempts";
    this.enumToLong["HORIZONTAL_SERIES"] = "horizontalSeries";
    this.enumToLong["ID"] = "id";
    this.enumToLong["IMAGE_THUMBNAIL"] = "imageThumbnail";
    this.enumToLong["IMAGE_THUMBNAIL_IS_VIDEO"] = "imageThumbnailIsVideo";
    this.enumToLong["IMAGE_URL"] = "imageUrl";
    this.enumToLong["INDIVIDUAL_ENTRIES"] = "individualEntries";
    this.enumToLong["INDIVIDUAL_EVENT"] = "individualEvent";
    this.enumToLong["INDIVIDUAL_EVENT_ID"] = "individualEventId";
    this.enumToLong["INDIVIDUAL_EVENTS"] = "individualEvents";
    this.enumToLong["INDIVIDUAL_HEATS"] = "individualHeats";
    this.enumToLong["INDIVIDUAL_RECORD"] = "individualRecord";
    this.enumToLong["INDIVIDUAL_RECORD_ID"] = "individualRecordId";
    this.enumToLong["INDIVIDUAL_RECORDS"] = "individualRecords";
    this.enumToLong["INDIVIDUAL_RESULT_ID"] = "individualResultId";
    this.enumToLong["INDIVIDUAL_RESULTS"] = "individualResults";
    this.enumToLong["INDIVIDUAL_RUN_SPLITS"] = "individualRunSplits";
    this.enumToLong["INDIVIDUAL_TIME_STANDARD"] = "individualTimeStandard";
    this.enumToLong["INDIVIDUAL_TIME_STANDARDS"] = "individualTimeStandards";
    this.enumToLong["INITIAL_RESULT_UPLOAD_DATE"] = "initialResultUploadDate";
    this.enumToLong["INTEGER_MARK"] = "integerMark";
    this.enumToLong["IS_FIELD_RUN_RELAY"] = "isFieldRunRelay";
    this.enumToLong["IS_FINAL"] = "isFinal";
    this.enumToLong["IS_LIVE"] = "isLive";
    this.enumToLong["IS_XC"] = "isXc";
    this.enumToLong["JUMPS"] = "jumps";
    this.enumToLong["KEY"] = "key";
    this.enumToLong["LANE"] = "lane";
    this.enumToLong["LAPS_TO_GO"] = "lapsToGo";
    this.enumToLong["LAST_MARK"] = "lastMark";
    this.enumToLong["LAST_NAME"] = "lastName";
    this.enumToLong["LAST_SPLIT"] = "lastSplit";
    this.enumToLong["LICENSE"] = "license";
    this.enumToLong["LINK"] = "link";
    this.enumToLong["LOCATION"] = "location";
    this.enumToLong["LOCATION_SEARCH_AREA"] = "locationSearchArea";
    this.enumToLong["LOCATION_SUMMARY"] = "locationSummary";
    this.enumToLong["LOGO"] = "logo";
    this.enumToLong["LONG_NAME"] = "longName";
    this.enumToLong["MARGIN"] = "margin";
    this.enumToLong["MARK"] = "mark";
    this.enumToLong["MASTER_EVENT_CATEGORY"] = "masterEventCategory";
    this.enumToLong["MASTER_EVENT_ID"] = "masterEventId";
    this.enumToLong["MEASUREMENT"] = "measurement";
    this.enumToLong["MEDIA"] = "media";
    this.enumToLong["MEET_DATES"] = "meetDates";
    this.enumToLong["MEET_ID"] = "meetId";
    this.enumToLong["MEET_LINKS"] = "meetLinks";
    this.enumToLong["MEET_MANAGEMENT_SYSTEM"] = "meetMgmtSystem";
    this.enumToLong["MEET_NOTICES"] = "meetNotices";
    this.enumToLong["MEET_SCORE_REPORTS"] = "meetScoreReports";
    this.enumToLong["MEET_SCORES"] = "meetScores";
    this.enumToLong["MESSAGE"] = "message";
    this.enumToLong["METERS"] = "meters";
    this.enumToLong["MULTI_AGE_GROUP_TITLE"] = "multiAgeGroupTitle";
    this.enumToLong["MULTI_AGE_GROUPS"] = "multiAgeGroups";
    this.enumToLong["MVP_LISTS"] = "mvpLists";
    this.enumToLong["NAME"] = "name";
    this.enumToLong["NAME_AMPLIFIERS"] = "nameAmplifiers";
    this.enumToLong["NOTE_MSG"] = "noteMsg";
    this.enumToLong["NOTE_SUBJECT"] = "noteSubject";
    this.enumToLong["NOTES"] = "notes";
    this.enumToLong["NUM_FIELD_VIDEOS"] = "numFieldVideos";
    this.enumToLong["NUM_FINISH_LINE_IMAGES"] = "numFinishLineImages";
    this.enumToLong["NUM_HEATS"] = "numHeats";
    this.enumToLong["NUM_LIVE_FIELD_EVENTS"] = "numLiveFieldEvents";
    this.enumToLong["NUM_LIVE_TRACK_EVENTS"] = "numLiveTrackEvents";
    this.enumToLong["NUM_POINT_PLACES"] = "numPointPlaces";
    this.enumToLong["NUM_RACE_VIDEOS"] = "numRaceVideos";
    this.enumToLong["NUM_RESULTS"] = "numResults";
    this.enumToLong["NUM_SCORABLE_EVENTS"] = "numScorableEvents";
    this.enumToLong["NUM_SCORED_EVENTS"] = "numScoredEvents";
    this.enumToLong["NUM_SPLITS"] = "numSplits";
    this.enumToLong["NUM_TEAM_DISPLACERS"] = "numTeamDisplacers";
    this.enumToLong["NUM_TEAM_MEMBERS"] = "numTeamMembers";
    this.enumToLong["NUM_TEAM_SCORERS"] = "numTeamScorers";
    this.enumToLong["NUM_VIDEOS"] = "numVideos";
    this.enumToLong["NUMBER"] = "number";
    this.enumToLong["PARENT_DIVISION_BY_ENTRY_EVENT"] = "parentDivisionByEntryEvent";
    this.enumToLong["PARENT_DIVISION_BY_TEAM_EVENT"] = "parentDivisionByTeamEvent";
    this.enumToLong["PARENT_MULTI_AGE_GROUP_EVENT"] = "parentMultiAgeGroupEvent";
    this.enumToLong["PERSONAL_RECORD"] = "personalRecord";
    this.enumToLong["PERSONAL_RECORDS"] = "personalRecords";
    this.enumToLong["PHOTO_URL"] = "photoUrl";
    this.enumToLong["PLACE"] = "place";
    this.enumToLong["PLACE_CHANGE"] = "placeChange";
    this.enumToLong["PLACE_CHANGE_DIRECTION"] = "placeChangeDirection";
    this.enumToLong["PLACE_INTEGER"] = "placeInteger";
    this.enumToLong["POINTS"] = "points";
    this.enumToLong["PPL_ATHLETES"] = "pplAthletes";
    this.enumToLong["PPL_TEAM_NAMES"] = "pplTeamNames";
    this.enumToLong["PREVIEW"] = "preview";
    this.enumToLong["PRIMARY_EVENT_GROUP"] = "primaryEventGroup";
    this.enumToLong["PROJECTED_SCORE"] = "projectedScore";
    this.enumToLong["PROJECTED_SCORE_SCORED_EVENTS"] = "projectedScoreScoredEvents";
    this.enumToLong["PROTEST_END_DATE"] = "protestEndDate";
    this.enumToLong["PROTEST_END_DATE_MILLISECONDS"] = "protestEndDateMs";
    this.enumToLong["PROTEST_MGMT"] = "protestMgmt";
    this.enumToLong["PTTIMING_LINK"] = "pttimingLink";
    this.enumToLong["PUBLISHED"] = "published";
    this.enumToLong["QUALIFYING_SYMBOL"] = "qualifyingSymbol";
    this.enumToLong["QUESTIONS_EMAIL"] = "questionsEmail";
    this.enumToLong["QUESTIONS_NAME"] = "questionsName";
    this.enumToLong["RANK_NUMBER"] = "rankNumber";
    this.enumToLong["RANK_NUMBER_MALE"] = "rankNumberMale";
    this.enumToLong["RANK_NUMBER_FEMALE"] = "rankNumberFemale";
    this.enumToLong["RANKINGS"] = "rankings";
    this.enumToLong["RAW_NAME"] = "rawName";
    this.enumToLong["RAW_RESULT_URL"] = "rawResultUrl";
    this.enumToLong["REAC_TIME"] = "reacTime";
    this.enumToLong["REAC_TIME"] = "reacTime";
    this.enumToLong["RECAP"] = "recap";
    this.enumToLong["RECORD_BREAKERS"] = "recordBreakers";
    this.enumToLong["RECORD_SETS"] = "recordSets";
    this.enumToLong["RECORDS"] = "records";
    this.enumToLong["REGISTRATION_ID"] = "registrationId";
    this.enumToLong["RELAY_DESIGNATOR"] = "relayDesignator";
    this.enumToLong["RELAY_ENTRIES"] = "relayEntries";
    this.enumToLong["RELAY_ENTRY"] = "relayEntry";
    this.enumToLong["RELAY_ENTRY_MEMBERSHIPS"] = "relayEntryMemberships";
    this.enumToLong["RELAY_EVENT"] = "relayEvent";
    this.enumToLong["RELAY_EVENT_ID"] = "relayEventId";
    this.enumToLong["RELAY_EVENTS"] = "relayEvents";
    this.enumToLong["RELAY_HEATS"] = "relayHeats";
    this.enumToLong["RELAY_HOLDERS"] = "relayHolders";
    this.enumToLong["RELAY_MEMBERS"] = "relayMembers";
    this.enumToLong["RELAY_NAMES"] = "relayNames";
    this.enumToLong["RELAY_RECORD"] = "relayRecord";
    this.enumToLong["RELAY_RECORD_ID"] = "relayRecordId";
    this.enumToLong["RELAY_RECORDS"] = "relayRecords";
    this.enumToLong["RELAY_RESULT"] = "relayResult";
    this.enumToLong["RELAY_RESULT_ID"] = "relayResultId";
    this.enumToLong["RELAY_RESULT_MEMBERSHIPS"] = "relayResultMemberships";
    this.enumToLong["RELAY_RESULTS"] = "relayResults";
    this.enumToLong["RELAY_RUN_SPLITS"] = "relayRunSplits";
    this.enumToLong["RELAY_TIME_STANDARD"] = "relayTimeStandard";
    this.enumToLong["RELAY_TIME_STANDARDS"] = "relayTimeStandards";
    this.enumToLong["RESULT_HEAT_NUMBERS"] = "resultHeatNumbers";
    this.enumToLong["RESULT_ORDER"] = "resultOrder";
    this.enumToLong["RESULT_YEARS"] = "resultYears";
    this.enumToLong["RESULTS"] = "results";
    this.enumToLong["ROUND"] = "round";
    this.enumToLong["ROUND"] = "round";
    this.enumToLong["ROUND_FINAL_ALIVE_ID"] = "roundFinalAliveId";
    this.enumToLong["ROUND_ID"] = "roundId";
    this.enumToLong["ROUND_LONG"] = "roundLong";
    this.enumToLong["ROUND_NUMBER"] = "roundNumber";
    this.enumToLong["ROUND_PRELIM_ALIVE_ID"] = "roundPrelimAliveId";
    this.enumToLong["ROUND_QUARTER_ALIVE_ID"] = "roundQuarterAliveId";
    this.enumToLong["ROUND_SEMI_ALIVE_ID"] = "roundSemiAliveId";
    this.enumToLong["ROUND_SHORT"] = "roundShort";
    this.enumToLong["ROUND_SORT_KEY"] = "roundSortKey";
    this.enumToLong["SCORABLE_EVENTS"] = "scorableEvents";
    this.enumToLong["SCORABLE_EVENTS"] = "scorableEvents";
    this.enumToLong["SCORE"] = "score";
    this.enumToLong["SCORE_BY_DIVISION"] = "scoreByDivision";
    this.enumToLong["SCORE_BY_GENDER"] = "scoreByGender";
    this.enumToLong["SCORE_DIFF"] = "scoreDiff";
    this.enumToLong["SCORE_DIFF_DIRECTION"] = "scoreDiffDirection";
    this.enumToLong["SCORE_MEET"] = "scoreMeet";
    this.enumToLong["SCOREBOARD_ID"] = "scoreboardId";
    this.enumToLong["SCOREBOARD_STATUS"] = "scoreboardStatus";
    this.enumToLong["SCORED_EVENTS"] = "scoredEvents";
    this.enumToLong["SCORING_PLACE_HEADERS"] = "scoringPlaceHeaders";
    this.enumToLong["SCORING_PLACES"] = "scoringPlaces";
    this.enumToLong["SEASON_RECORD"] = "seasonRecord";
    this.enumToLong["SEASON_RECORDS"] = "seasonRecords";
    this.enumToLong["SECOND_PLACE"] = "secondPlace";
    this.enumToLong["SEED"] = "seed";
    this.enumToLong["SESSION"] = "session";
    this.enumToLong["SESSION_DAYS"] = "sessionDays";
    this.enumToLong["SESSION_ID"] = "sessionId";
    this.enumToLong["SESSION_SORT_ORDER"] = "sessionSortOrder";
    this.enumToLong["SESSIONS"] = "sessions";
    this.enumToLong["SHORT_DESCRIPTION"] = "shortDescription";
    this.enumToLong["SHORT_NAME"] = "shortName";
    this.enumToLong["SHOW_ADS"] = "showAds";
    this.enumToLong["SHOW_COMPILED_SCOREBOARD"] = "showCompiledScoreboard";
    this.enumToLong["SHOW_COMP_NUMS"] = "showCompNums";
    this.enumToLong["SHOW_DATE"] = "showDate";
    this.enumToLong["SHOW_FIELD_SB_WIND"] = "showFieldSbWind";
    this.enumToLong["SHOW_LIVE_COMBINED_TABLE"] = "showLiveCombinedTable";
    this.enumToLong["SHOW_LIVE_FIELD_SERIES"] = "showLiveFieldSeries";
    this.enumToLong["SHOW_PTTIMING_ANALYTICS_LINK"] = "showPttimingAnalyticsLink";
    this.enumToLong["SHOW_RAW_RESULTS"] = "showRawResults";
    this.enumToLong["SHOW_TEAM_SCORES"] = "showTeamScores";
    this.enumToLong["SHOW_TRACK_SB_WIND"] = "showTrackSbWind";
    this.enumToLong["SHOW_TRACK_SPLIT_GRID"] = "showTrackSplitGrid";
    this.enumToLong["SHOW_UNOFFICIAL_SB_RESULTS"] = "showUnofficialSbResults";
    this.enumToLong["SITE"] = "site";
    this.enumToLong["SITE_SKIN"] = "siteSkin";
    this.enumToLong["SORT_KEY"] = "sortKey";
    this.enumToLong["SORT_ORDER"] = "sortOrder";
    this.enumToLong["SPLIT"] = "split";
    this.enumToLong["SPLIT_DEFINITIONS"] = "splitDefinitions";
    this.enumToLong["SPLIT_GRID_ATHLETE_SPLITS"] = "splitGridAthleteSplits";
    this.enumToLong["SPLIT_GRID_ATHLETES"] = "splitGridAthletes";
    this.enumToLong["SPLIT_GRID_SPLIT_NAMES"] = "splitGridSplitNames";
    this.enumToLong["SPLIT_GRID_SPLIT_NUMBERS"] = "splitGridSplitNumbers";
    this.enumToLong["SPLIT_GRIDS"] = "splitGrids";
    this.enumToLong["SPLIT_ROWS"] = "splitRows";
    this.enumToLong["SPLIT_SESSION"] = "splitSession";
    this.enumToLong["SPLIT_SESSION_COMBINED_EVENTS"] = "splitSessionCombinedEvents";
    this.enumToLong["SPLIT_SESSION_DATE"] = "ssd";
    this.enumToLong["SPLIT_SESSION_INDIVIDUAL_EVENTS"] = "splitSessionIndividualEvents";
    this.enumToLong["SPLIT_SESSION_RELAY_EVENTS"] = "splitSessionRelayEvents";
    this.enumToLong["SPLIT_SESSION_SORT_ORDER"] = "splitSessionSortOrder";
    this.enumToLong["SPONSOR"] = "sponsor";
    this.enumToLong["SPORT"] = "sport";
    this.enumToLong["STANDINGS"] = "standings";
    this.enumToLong["START_DAY"] = "startDay";
    this.enumToLong["STATS_ATTRIBUTION_SNIPPET"] = "statsAttributionSnippet";
    this.enumToLong["SUB_EVENT_PLACE"] = "subEventPlace";
    this.enumToLong["SUBJECT"] = "subject";
    this.enumToLong["SUMMARY_GROUP"] = "summaryGroup";
    this.enumToLong["SUMMARY_GROUPS"] = "summaryGroups";
    this.enumToLong["SUMMARY_NAME"] = "summaryName";
    this.enumToLong["SYMBOL"] = "symbol";
    this.enumToLong["TEAM"] = "team";
    this.enumToLong["TEAM_ID"] = "teamId";
    this.enumToLong["TEAM_NAME"] = "teamName";
    this.enumToLong["TEAM_ORDER"] = "teamOrder";
    this.enumToLong["TEAM_SCORE_CELLS"] = "teamScoreCells";
    this.enumToLong["TEAM_SCORE_ROWS"] = "teamScoreRows";
    this.enumToLong["TFRRS_LINK"] = "tfl";
    this.enumToLong["TIME_STANDARDS"] = "timeStandards";
    this.enumToLong["TIME_TRIAL_MODE"] = "timeTrialMode";
    this.enumToLong["TIME_TRIAL_START_DATE_MILLISECONDS"] = "timeTrialStartDateMs";
    this.enumToLong["TIMER_NAME"] = "timerName";
    this.enumToLong["TIMER_URL"] = "timerUrl";
    this.enumToLong["TIMEZONE"] = "timezone";
    this.enumToLong["TOP_FINISHER_IMAGE_URLS"] = "topFinisherImageUrls";
    this.enumToLong["TOP_LOGO_FLAT_FILENAME"] = "topLogoFlatFilename";
    this.enumToLong["TWITTER_SHARE_URL"] = "twitterShareUrl";
    this.enumToLong["TYPE"] = "type";
    this.enumToLong["UP_NOW"] = "upNow";
    this.enumToLong["UPDATE_ON_CREATE"] = "updateOnCreate";
    this.enumToLong["UPDATE_ON_UPDATE"] = "updateOnUpdate";
    this.enumToLong["UPDATED_AT"] = "updatedAt";
    this.enumToLong["URL"] = "url";
    this.enumToLong["URL_SLUG"] = "urlSlug";
    this.enumToLong["VALID_MARK"] = "validMark";
    this.enumToLong["VERTICAL_SERIES"] = "verticalSeries";
    this.enumToLong["VIDEO_DURATION"] = "videoDuration";
    this.enumToLong["VIDEO_LINK"] = "videoLink";
    this.enumToLong["VIDEO_POSTER_IMAGE_URL"] = "videoPosterImageUrl";
    this.enumToLong["VIDEO_THUMBNAIL_IMAGE_URL"] = "videoThumbnailImageUrl";
    this.enumToLong["VIDEO_UPLOAD_IN_PROGRESS"] = "videoUploadInProgress";
    this.enumToLong["WA_LINK"] = "waLink";
    this.enumToLong["WIN_MARGINS"] = "winMargins";
    this.enumToLong["WIND"] = "wind";
    this.enumToLong["WINNERS"] = "winners";
    this.enumToLong["XC_EVENT_SPLITS"] = "xcEventSplits";
    this.enumToLong["XC_SCORE_REPORT_ID"] = "xcScoreReportId";
    this.enumToLong["XC_SCORE_SCORERS_AND_DISPLACERS_AVG_TIME"] = "xcScoreScorersAndDisplacersAvgTime";
    this.enumToLong["XC_SCORE_SCORERS_AND_DISPLACERS_GAP"] = "xcScoreScorersAndDisplacersGap";
    this.enumToLong["XC_SCORE_SCORERS_AND_DISPLACERS_TOTAL_TIME"] = "xcScoreScorersAndDisplacersTotalTime";
    this.enumToLong["XC_SCORE_SCORERS_AVG_TIME"] = "xcScoreScorersAvgTime";
    this.enumToLong["XC_SCORE_SCORERS_GAP"] = "xcScoreScorersGap";
    this.enumToLong["XC_SCORE_SCORERS_TOTAL_TIME"] = "xcScoreScorersTotalTime";
    this.enumToLong["XC_SPLIT_SCORE_REPORT_ID"] = "xcSplitScoreReportId";
    this.enumToLong["YEAR"] = "year";
    this.enumToLong["sve"] = "SCORABLE_EVENTS";
    this.shortToEnum["a"] = "ATHLETE";
    this.shortToEnum["aan"] = "ALLOW_ATHLETE_NOTIFICATIONS";
    this.shortToEnum["ab"] = "ABBREV";
    this.shortToEnum["abn"] = "ABBREV_NAME";
    this.shortToEnum["abs"] = "EVENT_ABBREVS";
    this.shortToEnum["ac"] = "ARCHIVE_COUNTS";
    this.shortToEnum["aci"] = "ACCOUNT_ID";
    this.shortToEnum["ack"] = "ATHLETICS_CANADA_LINK";
    this.shortToEnum["afs"] = "ALLOW_FIELD_SCOREBOARD";
    this.shortToEnum["afvd"] = "ALLOW_FREE_ATHLETE_VIDEO_DOWNLOADS";
    this.shortToEnum["afmv"] = "ALLOW_FREE_MY_VIDEO_VIEWS";
    this.shortToEnum["ag"] = "AGE";
    this.shortToEnum["agm"] = "AGE_GRADE_MARK";
    this.shortToEnum["agp"] = "AGE_GRADE_PCT";
    this.shortToEnum["ai"] = "ATHLETE_ID";
    this.shortToEnum["al"] = "AWARD_LISTS";
    this.shortToEnum["ald"] = "AWARD_LIST_DATE";
    this.shortToEnum["alg"] = "ATHLETIC_NET_LOGO";
    this.shortToEnum["ali"] = "AWARD_LIST_ID";
    this.shortToEnum["alv"] = "AWARD_LIST_VERSION";
    this.shortToEnum["an"] = "ALLOW_NOTIFICATIONS";
    this.shortToEnum["ane"] = "ATHLETIC_NET_EVENT_ID";
    this.shortToEnum["ani"] = "ATHLETIC_NET_ID";
    this.shortToEnum["anis"] = "ATHLETIC_NET_MEETS";
    this.shortToEnum["anli"] = "ATHLETIC_LIVE_ID";
    this.shortToEnum["anlti"] = "ATHLETIC_LIVE_TEAM_ID";
    this.shortToEnum["ano"] = "ADVANCE_NOTES";
    this.shortToEnum["ap"] = "AGE_GROUP";
    this.shortToEnum["aps"] = "AGE_GROUPS";
    this.shortToEnum["arm"] = "ARMED_RUNNING";
    this.shortToEnum["as"] = "ALLOW_SCOREBOARD";
    this.shortToEnum["at"] = "AD_TYPE";
    this.shortToEnum["atn"] = "ALLOW_TEAM_NOTIFICATIONS";
    this.shortToEnum["atr"] = "ATTRIBUTIONS";
    this.shortToEnum["att"] = "ATTEMPT";
    this.shortToEnum["avd"] = "ALLOW_VIDEO_DOWNLOADS";
    this.shortToEnum["avk"] = "AVG_KM";
    this.shortToEnum["avm"] = "AVG_MILE";
    this.shortToEnum["awc"] = "AWARD_COUNT";
    this.shortToEnum["axn"] = "ALLOW_TEXT_NOTIFICATIONS";
    this.shortToEnum["bl"] = "BIB_LIST";
    this.shortToEnum["bm"] = "BEST_MARK";
    this.shortToEnum["bs"] = "BEST_SPLIT";
    this.shortToEnum["bv"] = "BLOCK_VIDEOS";
    this.shortToEnum["ca"] = "COMPETITION_AREA";
    this.shortToEnum["cc"] = "CONTEXT_CLASS";
    this.shortToEnum["ccl"] = "CANCELLED";
    this.shortToEnum["ccp"] = "CUMULATIVE_COMBINED_POINTS";
    this.shortToEnum["cd"] = "CREATED_DATE";
    this.shortToEnum["ce"] = "COMBINED_EVENT_NAME";
    this.shortToEnum["cea"] = "COMBINED_EVENT_ATHLETES";
    this.shortToEnum["cei"] = "COMBINED_EVENT_ID";
    this.shortToEnum["cen"] = "COMBINED_EVENT_NUM";
    this.shortToEnum["cer"] = "COMBINED_EVENT_RESULTS";
    this.shortToEnum["cgn"] = "CONCIERGE_NAME";
    this.shortToEnum["ci"] = "CHECK_IN";
    this.shortToEnum["ciend"] = "CHECK_IN_DATE";
    this.shortToEnum["cimilli"] = "CHECK_IN_END_DATE_MILLISECONDS";
    this.shortToEnum["cis"] = "CURRENT_INDIVIDUAL_SPLIT";
    this.shortToEnum["cm"] = "COMP_NUM";
    this.shortToEnum["cn"] = "COMBINED_ENTRIES";
    this.shortToEnum["cnm"] = "COMPETITION_AREA_NAME";
    this.shortToEnum["cna"] = "CUSTOM_NAME";
    this.shortToEnum["cno"] = "CUSTOM_NOTE";
    this.shortToEnum["co"] = "CONVERSION";
    this.shortToEnum["cp"] = "COMBINED_POINTS";
    this.shortToEnum["cpb"] = "COMBINED_POINTS_BEHIND";
    this.shortToEnum["cr"] = "COMBINED_RECORD";
    this.shortToEnum["cri"] = "COMBINED_RECORD_ID";
    this.shortToEnum["crs"] = "COMBINED_RECORDS";
    this.shortToEnum["cs"] = "CUMULATIVE_SPLIT";
    this.shortToEnum["ctd"] = "COMBINED_TIME_STANDARD";
    this.shortToEnum["ctds"] = "COMBINED_TIME_STANDARDS";
    this.shortToEnum["cts"] = "CURRENT_TEAM_SPLIT";
    this.shortToEnum["cv"] = "COMBINED_EVENT";
    this.shortToEnum["cvs"] = "COMBINED_EVENTS";
    this.shortToEnum["cy"] = "CONSECUTIVE_DAYS";
    this.shortToEnum["d"] = "DISTANCE";
    this.shortToEnum["dcn"] = "DATA_CONTEXT_NAME";
    this.shortToEnum["dixc"] = "DISPLAY_XC_INDIVIDUAL_FINISH_RESULTS";
    this.shortToEnum["dns"] = "DNS";
    this.shortToEnum["dnf"] = "DNF";
    this.shortToEnum["dp"] = "DESCRIPTION";
    this.shortToEnum["ds"] = "DATE_STRING";
    this.shortToEnum["dtxc"] = "DISPLAY_XC_TEAM_FINISH_RESULTS";
    this.shortToEnum["dv"] = "DIVISION";
    this.shortToEnum["dve"] = "DIVISION_BY_ENTRY_EVENTS";
    this.shortToEnum["dvi"] = "DIVISION_ID";
    this.shortToEnum["dvs"] = "DIVISIONS";
    this.shortToEnum["dvt"] = "DIVISION_BY_TEAM_EVENTS";
    this.shortToEnum["dxc"] = "DISPLAY_XC_FINISH_RESULTS";
    this.shortToEnum["e"] = "SITE";
    this.shortToEnum["ec"] = "EVENT_CATEGORY";
    this.shortToEnum["ed"] = "END_DATE";
    this.shortToEnum["egs"] = "EVENT_GROUPS";
    this.shortToEnum["ei"] = "EVENT_ID";
    this.shortToEnum["en"] = "INDIVIDUAL_ENTRIES";
    this.shortToEnum["enu"] = "EVENT_NUMBER";
    this.shortToEnum["eo"] = "EVENT_NOTES";
    this.shortToEnum["er"] = "SEASON_RECORD";
    this.shortToEnum["ern"] = "ENTITY_RECORD_NOTE";
    this.shortToEnum["ers"] = "SEASON_RECORDS";
    this.shortToEnum["es"] = "EVENT_STATUS";
    this.shortToEnum["etn"] = "EVENT_TYPE";
    this.shortToEnum["ev"] = "INDIVIDUAL_EVENT";
    this.shortToEnum["evs"] = "INDIVIDUAL_EVENTS";
    this.shortToEnum["f"] = "FULL_NAME";
    this.shortToEnum["fc"] = "FINISH_PERCENT";
    this.shortToEnum["fd"] = "FINISHED";
    this.shortToEnum["fdp"] = "FEATURED_PRIMARY";
    this.shortToEnum["fds"] = "FEATURED_SITE";
    this.shortToEnum["feo"] = "FIELD_EVENT_ORIENTATION";
    this.shortToEnum["fi"] = "FONT_ID";
    this.shortToEnum["fiav"] = "FIELD_APP_VIDEO";
    this.shortToEnum["fiavs"] = "FIELD_APP_VIDEOS";
    this.shortToEnum["fip"] = "FINAL_POSITION";
    this.shortToEnum["flbi"] = "FINISH_LINE_BRIGHTCOVE_ID";
    this.shortToEnum["fn"] = "FIRST_NAME";
    this.shortToEnum["fp"] = "FIRST_PLACE";
    this.shortToEnum["frua"] = "FINAL_RESULTS_UPLOADED_AT";
    this.shortToEnum["fruam"] = "FINAL_RESULTS_UPLOADED_AT_MILLISECONDS";
    this.shortToEnum["frv"] = "FULL_RACE_VIDEO_MODEL";
    this.shortToEnum["frva"] = "FULL_RACE_VIDEO_SUB_ACCESS";
    this.shortToEnum["frvh"] = "FULL_RACE_VIDEO_FREE_HOURS";
    this.shortToEnum["frvi"] = "FULL_RACE_VIDEO_IDS";
    this.shortToEnum["frvs"] = "FULL_RACE_VIDEO_MODELS";
    this.shortToEnum["frvt"] = "FULL_RACE_VIDEO_TITLES";
    this.shortToEnum["fs"] = "FIELD_SERIES";
    this.shortToEnum["fss"] = "FEATURE_SCORES";
    this.shortToEnum["fsu"] = "FACEBOOK_SHARE_URL";
    this.shortToEnum["ftn"] = "FLIGHT_NUMBER";
    this.shortToEnum["fts"] = "FLIGHTS";
    this.shortToEnum["fv"] = "FIELD_APP_VIDEO_MODEL";
    this.shortToEnum["fva"] = "FIELD_APP_VIDEO_SUB_ACCESS";
    this.shortToEnum["fvh"] = "FIELD_APP_VIDEO_FREE_HOURS";
    this.shortToEnum["fvs"] = "FIELD_APP_VIDEO_MODELS";
    this.shortToEnum["g"] = "GENDER";
    this.shortToEnum["ga"] = "GENDER_ABBREV";
    this.shortToEnum["gi"] = "GENDER_ID";
    this.shortToEnum["gl"] = "GENDER_LABEL";
    this.shortToEnum["gls"] = "GENDER_LABELS";
    this.shortToEnum["h"] = "HEAT";
    this.shortToEnum["hes"] = "HEATS";
    this.shortToEnum["hat"] = "HORIZONTAL_ATTEMPTS";
    this.shortToEnum["hbl"] = "HAS_BIB_LIST";
    this.shortToEnum["hcc"] = "HEAT_CONTEXT_CLASS";
    this.shortToEnum["he"] = "HAS_ENTRIES";
    this.shortToEnum["hew"] = "HEAT_WIND_READINGS";
    this.shortToEnum["hfav"] = "HAS_FIELD_APP_VIDEOS";
    this.shortToEnum["hfli"] = "HAS_FINISH_LINE_IMAGES";
    this.shortToEnum["hflv"] = "HAS_FINISH_LINE_VIDEOS";
    this.shortToEnum["hfrv"] = "HAS_FULL_RACE_VIDEOS";
    this.shortToEnum["hg"] = "HAS_LOGOS";
    this.shortToEnum["hgt"] = "HEIGHT";
    this.shortToEnum["hh"] = "HAS_HEATS";
    this.shortToEnum["hhs"] = "HAS_HORIZONTAL_SERIES";
    this.shortToEnum["hie"] = "HAS_INDIVIDUAL_ENTRIES";
    this.shortToEnum["hir"] = "HAS_INDIVIDUAL_RESULTS";
    this.shortToEnum["hl"] = "HEAT_PLACE";
    this.shortToEnum["hli"] = "HEAT_PLACE_INTEGER";
    this.shortToEnum["hmvp"] = "HAS_TRACK_POINT_MVP";
    this.shortToEnum["hn"] = "HEAT_NUMBER";
    this.shortToEnum["hno"] = "HEADER_NOTES";
    this.shortToEnum["ho"] = "HOLDER";
    this.shortToEnum["hol"] = "HIDE_ON_EVENT_LIST";
    this.shortToEnum["hp"] = "HAS_POINTS";
    this.shortToEnum["hpc"] = "HEAT_PLACE_CHANGE";
    this.shortToEnum["hpd"] = "HEAT_PLACE_CHANGE_DIRECTION";
    this.shortToEnum["hpp"] = "HAS_PROFILE_PICS";
    this.shortToEnum["hr"] = "HAS_RESULTS";
    this.shortToEnum["hrd"] = "HAS_RECORDS";
    this.shortToEnum["hre"] = "HAS_RELAY_ENTRIES";
    this.shortToEnum["hrh"] = "HAS_RESULT_HEATS";
    this.shortToEnum["hrr"] = "HAS_RELAY_RESULTS";
    this.shortToEnum["hs"] = "HORIZONTAL_SERIES";
    this.shortToEnum["hsc"] = "HIDE_SCORES";
    this.shortToEnum["hsg"] = "HAS_SPLIT_GRIDS";
    this.shortToEnum["hss"] = "HAS_SESSIONS";
    this.shortToEnum["ht"] = "HASH_TAG";
    this.shortToEnum["htf"] = "HAS_TOP_FINISHER_IMAGES";
    this.shortToEnum["hts"] = "HAS_TIME_STANDARDS";
    this.shortToEnum["hvs"] = "HAS_VERTICAL_SERIES";
    this.shortToEnum["hwr"] = "HAS_WIND_READINGS";
    this.shortToEnum["hy"] = "HAS_YEARS";
    this.shortToEnum["i"] = "ID";
    this.shortToEnum["iei"] = "INDIVIDUAL_EVENT_ID";
    this.shortToEnum["ifi"] = "IS_FINAL";
    this.shortToEnum["ifrr"] = "IS_FIELD_RUN_RELAY";
    this.shortToEnum["im"] = "INTEGER_MARK";
    this.shortToEnum["ird"] = "INDIVIDUAL_RECORD";
    this.shortToEnum["irdi"] = "INDIVIDUAL_RECORD_ID";
    this.shortToEnum["irds"] = "INDIVIDUAL_RECORDS";
    this.shortToEnum["irs"] = "INDIVIDUAL_RUN_SPLITS";
    this.shortToEnum["it"] = "INDIVIDUAL_HEATS";
    this.shortToEnum["itd"] = "INDIVIDUAL_TIME_STANDARD";
    this.shortToEnum["itds"] = "INDIVIDUAL_TIME_STANDARDS";
    this.shortToEnum["ith"] = "IMAGE_THUMBNAIL";
    this.shortToEnum["ithv"] = "IMAGE_THUMBNAIL_IS_VIDEO";
    this.shortToEnum["iu"] = "IMAGE_URL";
    this.shortToEnum["j"] = "JUMPS";
    this.shortToEnum["k"] = "KEY";
    this.shortToEnum["l"] = "LAST_NAME";
    this.shortToEnum["la"] = "LANE";
    this.shortToEnum["lg"] = "LOGO";
    this.shortToEnum["li"] = "LICENSE";
    this.shortToEnum["lk"] = "LINK";
    this.shortToEnum["lm"] = "LAST_MARK";
    this.shortToEnum["ln"] = "LONG_NAME";
    this.shortToEnum["lo"] = "LOCATION";
    this.shortToEnum["lp"] = "LAST_SPLIT";
    this.shortToEnum["ls"] = "LOCATION_SUMMARY";
    this.shortToEnum["lsa"] = "LOCATION_SEARCH_AREA";
    this.shortToEnum["lt"] = "LAPS_TO_GO";
    this.shortToEnum["lv"] = "IS_LIVE";
    this.shortToEnum["m"] = "MARK";
    this.shortToEnum["mag"] = "MULTI_AGE_GROUPS";
    this.shortToEnum["magt"] = "MULTI_AGE_GROUP_TITLE";
    this.shortToEnum["md"] = "MEET_DATES";
    this.shortToEnum["me"] = "MEASUREMENT";
    this.shortToEnum["mec"] = "MASTER_EVENT_CATEGORY";
    this.shortToEnum["med"] = "MEDIA";
    this.shortToEnum["mei"] = "MASTER_EVENT_ID";
    this.shortToEnum["mg"] = "MESSAGE";
    this.shortToEnum["mi"] = "MEET_ID";
    this.shortToEnum["ml"] = "MEET_LINKS";
    this.shortToEnum["mms"] = "MEET_MANAGEMENT_SYSTEM";
    this.shortToEnum["mn"] = "MEET_NOTICES";
    this.shortToEnum["mr"] = "MARGIN";
    this.shortToEnum["mt"] = "METERS";
    this.shortToEnum["ms"] = "MEET_SCORES";
    this.shortToEnum["msrs"] = "MEET_SCORE_REPORTS";
    this.shortToEnum["mvp"] = "MVP_LISTS";
    this.shortToEnum["n"] = "NAME";
    this.shortToEnum["na"] = "NAME_AMPLIFIERS";
    this.shortToEnum["nfli"] = "NUM_FINISH_LINE_IMAGES";
    this.shortToEnum["nfv"] = "NUM_FIELD_VIDEOS";
    this.shortToEnum["nh"] = "NUM_HEATS";
    this.shortToEnum["nlfe"] = "NUM_LIVE_FIELD_EVENTS";
    this.shortToEnum["nlte"] = "NUM_LIVE_TRACK_EVENTS";
    this.shortToEnum["no"] = "NOTES";
    this.shortToEnum["nom"] = "NOTE_MSG";
    this.shortToEnum["nos"] = "NOTE_SUBJECT";
    this.shortToEnum["npp"] = "NUM_POINT_PLACES";
    this.shortToEnum["nr"] = "NUM_RESULTS";
    this.shortToEnum["nrv"] = "NUM_RACE_VIDEOS";
    this.shortToEnum["ns"] = "NUM_SPLITS";
    this.shortToEnum["nsv"] = "NUM_SCORED_EVENTS";
    this.shortToEnum["nsve"] = "NUM_SCORABLE_EVENTS";
    this.shortToEnum["ntd"] = "NUM_TEAM_DISPLACERS";
    this.shortToEnum["ntm"] = "NUM_TEAM_MEMBERS";
    this.shortToEnum["nts"] = "NUM_TEAM_SCORERS";
    this.shortToEnum["nu"] = "NUMBER";
    this.shortToEnum["nv"] = "NUM_VIDEOS";
    this.shortToEnum["o"] = "SPORT";
    this.shortToEnum["p"] = "PLACE";
    this.shortToEnum["pb"] = "PUBLISHED";
    this.shortToEnum["pc"] = "PLACE_CHANGE";
    this.shortToEnum["pd"] = "PLACE_CHANGE_DIRECTION";
    this.shortToEnum["pdve"] = "PARENT_DIVISION_BY_ENTRY_EVENT";
    this.shortToEnum["pdvt"] = "PARENT_DIVISION_BY_TEAM_EVENT";
    this.shortToEnum["peg"] = "PRIMARY_EVENT_GROUP";
    this.shortToEnum["psc"] = "PROJECTED_SCORE";
    this.shortToEnum["pscse"] = "PROJECTED_SCORE_SCORED_EVENTS";
    this.shortToEnum["pend"] = "PROTEST_END_DATE";
    this.shortToEnum["phu"] = "PHOTO_URL";
    this.shortToEnum["pi"] = "PLACE_INTEGER";
    this.shortToEnum["pmag"] = "PARENT_MULTI_AGE_GROUP_EVENT";
    this.shortToEnum["pmgmt"] = "PROTEST_MGMT";
    this.shortToEnum["pmilli"] = "PROTEST_END_DATE_MILLISECONDS";
    this.shortToEnum["ppla"] = "PPL_ATHLETES";
    this.shortToEnum["pplt"] = "PPL_TEAM_NAMES";
    this.shortToEnum["pr"] = "PERSONAL_RECORD";
    this.shortToEnum["prs"] = "PERSONAL_RECORDS";
    this.shortToEnum["pt"] = "POINTS";
    this.shortToEnum["pttl"] = "PTTIMING_LINK";
    this.shortToEnum["ptal"] = "SHOW_PTTIMING_ANALYTICS_LINK";
    this.shortToEnum["pv"] = "PREVIEW";
    this.shortToEnum["q"] = "QUALIFYING_SYMBOL";
    this.shortToEnum["qe"] = "QUESTIONS_EMAIL";
    this.shortToEnum["qn"] = "QUESTIONS_NAME";
    this.shortToEnum["r"] = "INDIVIDUAL_RESULTS";
    this.shortToEnum["rb"] = "RECORD_BREAKERS";
    this.shortToEnum["rc"] = "REAC_TIME";
    this.shortToEnum["rc"] = "REAC_TIME";
    this.shortToEnum["rcs"] = "RECORDS";
    this.shortToEnum["rd"] = "RELAY_DESIGNATOR";
    this.shortToEnum["rem"] = "RELAY_ENTRY_MEMBERSHIPS";
    this.shortToEnum["resd"] = "INITIAL_RESULT_UPLOAD_DATE";
    this.shortToEnum["rfai"] = "ROUND_FINAL_ALIVE_ID";
    this.shortToEnum["rg"] = "REGISTRATION_ID";
    this.shortToEnum["rh"] = "RELAY_HOLDERS";
    this.shortToEnum["rhn"] = "RESULT_HEAT_NUMBERS";
    this.shortToEnum["ri"] = "INDIVIDUAL_RESULT_ID";
    this.shortToEnum["rknr"] = "RANK_NUMBER";
    this.shortToEnum["rknfe"] = "RANK_NUMBER_FEMALE";
    this.shortToEnum["rknma"] = "RANK_NUMBER_MALE";
    this.shortToEnum["rks"] = "RANKINGS";
    this.shortToEnum["rm"] = "RELAY_MEMBERS";
    this.shortToEnum["rn"] = "RELAY_ENTRY";
    this.shortToEnum["rnm"] = "RELAY_NAMES";
    this.shortToEnum["rns"] = "RELAY_ENTRIES";
    this.shortToEnum["ro"] = "RESULT_ORDER";
    this.shortToEnum["rp"] = "RECAP";
    this.shortToEnum["rpai"] = "ROUND_PRELIM_ALIVE_ID";
    this.shortToEnum["rqai"] = "ROUND_QUARTER_ALIVE_ID";
    this.shortToEnum["rr"] = "RELAY_RECORD";
    this.shortToEnum["rre"] = "RELAY_RECORDS";
    this.shortToEnum["rri"] = "RELAY_RECORD_ID";
    this.shortToEnum["rrm"] = "RELAY_RESULT_MEMBERSHIPS";
    this.shortToEnum["rrs"] = "RELAY_RUN_SPLITS";
    this.shortToEnum["rru"] = "RAW_RESULT_URL";
    this.shortToEnum["rs"] = "RESULTS";
    this.shortToEnum["rsai"] = "ROUND_SEMI_ALIVE_ID";
    this.shortToEnum["rsk"] = "ROUND_SORT_KEY";
    this.shortToEnum["rsts"] = "RECORD_SETS";
    this.shortToEnum["rt"] = "RELAY_RESULT";
    this.shortToEnum["rtd"] = "RELAY_TIME_STANDARD";
    this.shortToEnum["rtds"] = "RELAY_TIME_STANDARDS";
    this.shortToEnum["rti"] = "RELAY_RESULT_ID";
    this.shortToEnum["rtn"] = "RELAY_HEATS";
    this.shortToEnum["rts"] = "RELAY_RESULTS";
    this.shortToEnum["ru"] = "ROUND";
    this.shortToEnum["rui"] = "ROUND_ID";
    this.shortToEnum["rul"] = "ROUND_LONG";
    this.shortToEnum["run"] = "ROUND_NUMBER";
    this.shortToEnum["rus"] = "ROUND_SHORT";
    this.shortToEnum["rv"] = "RELAY_EVENT";
    this.shortToEnum["rvi"] = "RELAY_EVENT_ID";
    this.shortToEnum["rvs"] = "RELAY_EVENTS";
    this.shortToEnum["rwn"] = "RAW_NAME";
    this.shortToEnum["ry"] = "RESULT_YEARS";
    this.shortToEnum["s"] = "SEED";
    this.shortToEnum["sa"] = "SHOW_ADS";
    this.shortToEnum["sas"] = "STATS_ATTRIBUTION_SNIPPET";
    this.shortToEnum["sb"] = "SUBJECT";
    this.shortToEnum["sbd"] = "SCORE_BY_DIVISION";
    this.shortToEnum["sbg"] = "SCORE_BY_GENDER";
    this.shortToEnum["sbi"] = "SCOREBOARD_ID";
    this.shortToEnum["sc"] = "SCORE";
    this.shortToEnum["scns"] = "SHOW_COMP_NUMS";
    this.shortToEnum["scs"] = "SHOW_COMPILED_SCOREBOARD";
    this.shortToEnum["sct"] = "SHOW_LIVE_COMBINED_TABLE";
    this.shortToEnum["scvs"] = "SPLIT_SESSION_COMBINED_EVENTS";
    this.shortToEnum["sd"] = "DATE";
    this.shortToEnum["sdf"] = "SCORE_DIFF";
    this.shortToEnum["sdfd"] = "SCORE_DIFF_DIRECTION";
    this.shortToEnum["sdp"] = "SHORT_DESCRIPTION";
    this.shortToEnum["sdt"] = "SHOW_DATE";
    this.shortToEnum["sdy"] = "START_DAY";
    this.shortToEnum["se"] = "SESSION";
    this.shortToEnum["se2"] = "SPLIT_SESSION";
    this.shortToEnum["sep"] = "SUB_EVENT_PLACE";
    this.shortToEnum["ses"] = "SESSIONS";
    this.shortToEnum["sesds"] = "SESSION_DAYS";
    this.shortToEnum["sevs"] = "SPLIT_SESSION_INDIVIDUAL_EVENTS";
    this.shortToEnum["sfw"] = "SHOW_FIELD_SB_WIND";
    this.shortToEnum["sg"] = "SUMMARY_GROUP";
    this.shortToEnum["sga"] = "SPLIT_GRID_ATHLETES";
    this.shortToEnum["sgas"] = "SPLIT_GRID_ATHLETE_SPLITS";
    this.shortToEnum["sgna"] = "SPLIT_GRID_SPLIT_NAMES";
    this.shortToEnum["sgnu"] = "SPLIT_GRID_SPLIT_NUMBERS";
    this.shortToEnum["sgs"] = "SPLIT_GRIDS";
    this.shortToEnum["si"] = "SESSION_ID";
    this.shortToEnum["sk"] = "SORT_KEY";
    this.shortToEnum["skn"] = "SITE_SKIN";
    this.shortToEnum["sl"] = "SECOND_PLACE";
    this.shortToEnum["sls"] = "SHOW_LIVE_FIELD_SERIES";
    this.shortToEnum["sm"] = "SCORE_MEET";
    this.shortToEnum["smgs"] = "SUMMARY_GROUPS";
    this.shortToEnum["sn"] = "SHORT_NAME";
    this.shortToEnum["so"] = "SORT_ORDER";
    this.shortToEnum["sp"] = "SPLIT";
    this.shortToEnum["spd"] = "SPLIT_DEFINITIONS";
    this.shortToEnum["spl"] = "SCORING_PLACES";
    this.shortToEnum["splh"] = "SCORING_PLACE_HEADERS";
    this.shortToEnum["spr"] = "SPLIT_ROWS";
    this.shortToEnum["sr"] = "SPONSOR";
    this.shortToEnum["srr"] = "SHOW_RAW_RESULTS";
    this.shortToEnum["srvs"] = "SPLIT_SESSION_RELAY_EVENTS";
    this.shortToEnum["ss"] = "SCOREBOARD_STATUS";
    this.shortToEnum["ssd"] = "SPLIT_SESSION_DATE";
    this.shortToEnum["sso"] = "SESSION_SORT_ORDER";
    this.shortToEnum["sso2"] = "SPLIT_SESSION_SORT_ORDER";
    this.shortToEnum["st"] = "STANDINGS";
    this.shortToEnum["sts"] = "SHOW_TEAM_SCORES";
    this.shortToEnum["stsg"] = "SHOW_TRACK_SPLIT_GRID";
    this.shortToEnum["stw"] = "SHOW_TRACK_SB_WIND";
    this.shortToEnum["sur"] = "SHOW_UNOFFICIAL_SB_RESULTS";
    this.shortToEnum["sv"] = "SCORED_EVENTS";
    this.shortToEnum["sve"] = "SCORABLE_EVENTS";
    this.shortToEnum["sy"] = "SYMBOL";
    this.shortToEnum["t"] = "TEAM";
    this.shortToEnum["tfi"] = "TOP_FINISHER_IMAGE_URLS";
    this.shortToEnum["tfl"] = "TFRRS_LINK";
    this.shortToEnum["ti"] = "TEAM_ID";
    this.shortToEnum["tl"] = "TOP_LOGO_FLAT_FILENAME";
    this.shortToEnum["tn"] = "TEAM_NAME";
    this.shortToEnum["tna"] = "TIMER_NAME";
    this.shortToEnum["to"] = "TEAM_ORDER";
    this.shortToEnum["tp"] = "TYPE";
    this.shortToEnum["ts"] = "HAS_SPLITS";
    this.shortToEnum["tsc"] = "TEAM_SCORE_CELLS";
    this.shortToEnum["tsm"] = "TIME_TRIAL_START_DATE_MILLISECONDS";
    this.shortToEnum["tsr"] = "TEAM_SCORE_ROWS";
    this.shortToEnum["tst"] = "TIME_STANDARDS";
    this.shortToEnum["tsu"] = "TWITTER_SHARE_URL";
    this.shortToEnum["ttm"] = "TIME_TRIAL_MODE";
    this.shortToEnum["tu"] = "TIMER_URL";
    this.shortToEnum["tz"] = "TIMEZONE";
    this.shortToEnum["u"] = "URL";
    this.shortToEnum["ua"] = "UPDATED_AT";
    this.shortToEnum["un"] = "SUMMARY_NAME";
    this.shortToEnum["uoc"] = "UPDATE_ON_CREATE";
    this.shortToEnum["uou"] = "UPDATE_ON_UPDATE";
    this.shortToEnum["up"] = "UP_NOW";
    this.shortToEnum["us"] = "URL_SLUG";
    this.shortToEnum["v"] = "EVENT";
    this.shortToEnum["vdr"] = "VIDEO_DURATION";
    this.shortToEnum["vl"] = "VIDEO_LINK";
    this.shortToEnum["vm"] = "VALID_MARK";
    this.shortToEnum["vpg"] = "VIDEO_UPLOAD_IN_PROGRESS";
    this.shortToEnum["vpi"] = "VIDEO_POSTER_IMAGE_URL";
    this.shortToEnum["vs"] = "VERTICAL_SERIES";
    this.shortToEnum["vti"] = "VIDEO_THUMBNAIL_IMAGE_URL";
    this.shortToEnum["w"] = "WIND";
    this.shortToEnum["wlk"] = "WA_LINK";
    this.shortToEnum["wm"] = "WIN_MARGINS";
    this.shortToEnum["ws"] = "WINNERS";
    this.shortToEnum["xc"] = "IS_XC";
    this.shortToEnum["xcp"] = "XC_EVENT_SPLITS";
    this.shortToEnum["xda"] = "XC_SCORE_SCORERS_AND_DISPLACERS_AVG_TIME";
    this.shortToEnum["xdg"] = "XC_SCORE_SCORERS_AND_DISPLACERS_GAP";
    this.shortToEnum["xdt"] = "XC_SCORE_SCORERS_AND_DISPLACERS_TOTAL_TIME";
    this.shortToEnum["xsa"] = "XC_SCORE_SCORERS_AVG_TIME";
    this.shortToEnum["xsg"] = "XC_SCORE_SCORERS_GAP";
    this.shortToEnum["xsri"] = "XC_SCORE_REPORT_ID";
    this.shortToEnum["xssri"] = "XC_SPLIT_SCORE_REPORT_ID";
    this.shortToEnum["xst"] = "XC_SCORE_SCORERS_TOTAL_TIME";
    this.shortToEnum["y"] = "YEAR";
    Object.keys(this.shortToEnum).forEach(shortKey => {
      this.enumToShort[this.shortToEnum[shortKey]] = shortKey;
    });
    Object.keys(this.enumToLong).forEach(enumKey => {
      this.longToEnum[this.enumToLong[enumKey]] = enumKey;
    });
  }
}
exports.KeyMapService = KeyMapService;